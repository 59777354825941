import {AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MessageService} from '../message.service';

@Component({
  selector: 'app-message-outlet',
  templateUrl: './message-outlet.component.html',
  styleUrls: ['./message-outlet.component.scss']
})
export class MessageOutletComponent implements OnInit, AfterViewInit {
  // view container ref
  @ViewChild('container', {read: ViewContainerRef}) public viewContainerRef: ViewContainerRef;

  constructor(
    private messageService: MessageService,
  ) {
  }

  ngOnInit(): void {
  }

  /**
   * set viewContainerRef to service when view initialized
   */
  ngAfterViewInit(): void {
    this.messageService.viewContainerRef = this.viewContainerRef;
  }
}
