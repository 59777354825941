import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconWarnComponent } from './icon-warn.component';



@NgModule({
  declarations: [IconWarnComponent],
  exports: [
    IconWarnComponent
  ],
  imports: [
    CommonModule
  ]
})
export class IconWarnModule { }
