import { AM, SA, GA, CSA, SM, SU, UserRole } from '../models/user';

/**
 * return true when role is admin
 * @param role role
 */
export function isAdmin(role: UserRole) {
  return [SA, AM].indexOf(role) !== -1;
}

/**
 * return true when role is client user
 * @param role role
 */
export function isClientUser(role: UserRole) {
  return [CSA, GA].indexOf(role) !== -1;
}

/**
 * return true when role is site user
 * @param role role
 */
export function isSiteUser(role: UserRole) {
  return [SM, SU].indexOf(role) !== -1;
}

export function isOnlySiteUser(role: UserRole) {
  return role === SU;
}


/**
 * check user notification pref equal
 * @param oldPref the old ref
 * @param newPref the new ref
 */
export function checkNotificationPrefEql(oldPref, newPref) {
  return oldPref === newPref || oldPref.split(',').length === newPref.split(',').length;
}
