import { Injectable } from '@angular/core';
import { User } from '../models/user';

/**
 * set data to local storage
 */
export function setToLocalStorage<T>(key: string, data: T): void {
  if (localStorage) {
    localStorage.setItem(key, JSON.stringify(data));
  }
}

/**
 * get data form local storage
 */
export function getFromLocalStorage<T>(key: string): T {
  if (localStorage) {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    } else {
      return null;
    }
  }
}

/**
 * set data to session storage
 */
export function setToSessionStorage<T>(key: string, data: T): void {
  if (sessionStorage) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }
}

/**
 * get data form session storage
 */
export function getFromSessionStorage<T>(key: string): T {
  if (sessionStorage) {
    const data = sessionStorage.getItem(key);
    if (data) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  // user key
  private readonly userKey = 'ac-user';
  // stop client help step key
  private readonly stopClientHelpStepKey = 'ac-stop-client-help-step';

  constructor() {
  }

  /**
   * set user
   * @param user user
   * @param remember will write into localStorage if true
   */
  setUser(user: User, remember: boolean) {
    if (remember) {
      setToLocalStorage(this.userKey, user);
    } else {
      setToSessionStorage(this.userKey, user);
    }
  }

  /**
   * update user
   * @param user the user
   */
  updateUser(user: User) {
    const u1 = getFromSessionStorage<User>(this.userKey);
    if (!u1) {
      setToLocalStorage(this.userKey, user);
      return;
    }
    setToSessionStorage(this.userKey, user);
  }

  /**
   * clear user
   */
  clearUser() {
    setToSessionStorage(this.userKey, null);
    setToLocalStorage(this.userKey, null);
  }

  /**
   * get user
   */
  get user(): User {
    const u1 = getFromSessionStorage<User>(this.userKey);
    if (!u1) {
      return getFromLocalStorage(this.userKey);
    }
    return u1;
  }

  /**
   * set stop client help step
   * @param state state
   */
  set stopClientHelpStep(state: boolean) {
    setToLocalStorage(this.stopClientHelpStepKey, state);
  }

  /**
   * get stop client help step
   */
  get stopClientHelpStep(): boolean {
    return getFromLocalStorage(this.stopClientHelpStepKey);
  }
}
