export const environment = {
  production: true,
  api: {
    host: '/assets/data',
    delay: 0,
  },
  restApi: 'https://client-api.anadev.net/api/v1',
  // restApi: 'http://127.0.0.1:8081/api/v1',
  installerApp: 'https://alpha.anaprod.net/anastall/',
  termsOfService: {
    path: '/assets/data/terms-of-service.json',
  },
  siteOptions: {
    availableTimeOptions: ['Second', 'Minute', 'Hour', 'Day'],
  },
};
