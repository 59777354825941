<!-- title -->
<div class="ts-modal-title">
  <ng-content></ng-content>
</div>

<!-- close button -->
<svg
  *ngIf="!hideCloseIcon"
  (click)="closeModal()"
  class="ts-modal-close"
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="16"
  viewBox="0 0 16 16">
  <circle
    cx="8.0229998"
    cy="7.9770002"
    r="7.2729998"
    fill="none"
    stroke="#5f8fb7"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"/>
  <path
    d="M 10.204023,5.7939932 5.8400232,10.157993"
    fill="none"
    stroke="#5f8fb7"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"/>
  <path
    d="M 5.8400232,5.7939932 10.204023,10.157993"
    fill="none"
    stroke="#5f8fb7"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"/>
</svg>
