import {createAction, props} from '@ngrx/store';
import {User} from '../../models/user';
import {SideAlertsType} from '../../components/application/common/side-alerts/side-alerts.component';

export const MainStoreKey = 'MainStore';

export const mainStoreActions = {
  // set current user
  setUser: createAction(
    `${MainStoreKey}:setUser`,
    props<{user: User}>(),
  ),
  // set side alert type
  setSideAlertType: createAction(
    `${MainStoreKey}:setSideAlertType`,
    props<{sideAlertType: SideAlertsType}>(),
  ),
  // set client/side id to get alert
  setSideAlertId: createAction(
    `${MainStoreKey}:setSideAlertId`,
    props<{ sideAlertId: string }>(),
  ),
  // set search text
  setSearchText: createAction(
    `${MainStoreKey}:setSearchText`,
    props<{ search: string }>(),
  ),
  // set come from search result
  setComeFromSearchResult: createAction(
    `${MainStoreKey}:setComeFromSearchResult`,
    props<{ comeFromSearchResult: boolean }>(),
  ),
  // set user profile
  setUserProfile: createAction(
    `${MainStoreKey}:setUserProfile`,
    props<{ user: User }>(),
  ),
  // set signing state
  setSigning: createAction(
    `${MainStoreKey}:setSigning`,
    props<{ signing: boolean }>(),
  ),
  // clear main store should be called when user logging out
  clear: createAction(
    `${MainStoreKey}:clear`,
  )
};
