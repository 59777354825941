<!-- message left -->
<div class="ts-message-left">
  <!-- switch by type -->
  <ng-container [ngSwitch]="type">
    <!-- info -->
    <app-icon-info *ngSwitchCase="'info'"></app-icon-info>

    <!-- archive -->
    <app-icon-archive *ngSwitchCase="'archive'"></app-icon-archive>

    <!-- success -->
    <app-icon-success *ngSwitchCase="'success'"></app-icon-success>

    <!-- delete -->
    <app-icon-delete *ngSwitchCase="'delete'"></app-icon-delete>

    <!-- warn -->
    <app-icon-warn *ngSwitchCase="'warn'"></app-icon-warn>

    <!-- danger -->
    <app-icon-danger *ngSwitchCase="'danger'"></app-icon-danger>
  </ng-container>

  <!-- message -->
  <div class="ts-message">
    {{message}}
  </div>
</div>

<!-- message right -->
<div class="ts-message-right">
  {{count}}s
  <ng-container *ngIf="hasUndo">
    <div class="ts-vertical-div"></div>
    <div
      (click)="undoAction()"
      class="ts-undo-button">
      Undo
    </div>
  </ng-container>

  <!-- close icon -->
  <svg
    (click)="messageClose.emit()"
    class="ts-message-close"
    xmlns="http://www.w3.org/2000/svg" width="9.9" height="9.899" viewBox="0 0 9.9 9.899">
    <rect width="2" height="12" rx="1" transform="translate(8.485 0) rotate(45)"
          fill="#5f8fb7"/>
    <rect width="2" height="12" rx="1" transform="translate(9.899 8.485) rotate(135)"
          fill="#5f8fb7"/>
  </svg>
</div>
