import { Component, OnInit } from '@angular/core';
import {IconBaseComponent} from '../base/icon-base.component';

@Component({
  selector: 'app-icon-success',
  templateUrl: './icon-success.component.html',
  styleUrls: ['./icon-success.component.scss']
})
export class IconSuccessComponent extends IconBaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
