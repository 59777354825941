import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApplicationGuard } from './guards/application.guard';
import { HomeGuard } from './guards/home.guard';
import { SiteGuard } from './guards/site.guard';


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    canActivate: [
      HomeGuard,
    ],
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/application/application.module').then(m => m.ApplicationModule),
    canActivate: [
      ApplicationGuard,
    ],
  },
  {
    path: 'user-confirmation',
    loadChildren: () => import('./pages/user-confirmation/user-confirmation.module').then(m => m.UserConfirmationModule),
  },
  {
    path: 'a/:shortCodeId',
    loadChildren: () => import('./pages/short-url/short-url.module').then(m => m.ShortUrlModule),
  },
  {
    path: 'workorder/:clientId/:siteId',
    loadChildren: () => import('./pages/site-work-order/site-work-order.module').then(m => m.SiteWorkOrderModule),
    canActivate: [
      SiteGuard
    ]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
