import {createSelector} from '@ngrx/store';
import {MainStoreKey} from './main-store.actions';
import {MainStoreState} from './main-store.reducer';

export const selectMainStore = (state): MainStoreState => state[MainStoreKey];

export const mainStoreSelectors = {
  // get current user
  user: createSelector(
    selectMainStore,
    state => state.user,
  ),
  // get side alert type
  sideAlertType: createSelector(
    selectMainStore,
    state => state.sideAlertType,
  ),
  // get side alert id
  sideAlertId: createSelector(
    selectMainStore,
    state => state.sideAlertId,
  ),
  // get search text
  setSearchText: createSelector(
    selectMainStore,
    state => state.search,
  ),
  // get come from search result
  comeFromSearchResult: createSelector(
    selectMainStore,
    state => state.comeFromSearchResult,
  ),
  // get user profile
  userProfile: createSelector(
    selectMainStore,
    state => state.userProfile,
  ),
  // get signing state
  signing: createSelector(
    selectMainStore,
    state => state.signing,
  ),
};
