import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {RoleGuardBaseGuard} from './role-guard-base/role-guard-base.guard';
import {Store} from '@ngrx/store';
import {MessageService} from '../shared-components/message/message.service';
import {map} from 'rxjs/operators';
import {isAdmin, isClientUser, isSiteUser} from '../utils/user.util';

@Injectable({
  providedIn: 'root'
})
export class SiteGuard extends RoleGuardBaseGuard implements CanActivate {
  constructor(
    protected store: Store,
    protected router: Router,
    protected messageService: MessageService,
  ) {
    super(
      store,
      router,
      messageService,
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.user$
      .pipe(map(user => {
        const can = user
          && (
            isAdmin(user.role)
            || isClientUser(user.role)
            || (
              isSiteUser(user.role)
              && next.paramMap.get('clientId') === user.clientId
              && next.paramMap.get('siteId') === user.siteId
            )
          );

        if (!can) {
          if (isSiteUser(user.role)) {
            this.router.navigate(['/app/site', user.clientId, user.siteId]);
            this.messageService.open('warn', `User doesn't have access to page`);
          }
        }

        return can;
      }));
  }

}
