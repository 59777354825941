import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
  OnDestroy,
  Type,
  ViewContainerRef
} from '@angular/core';
import {ModalBackgroundComponent} from './modal-background/modal-background.component';
import {ModalContentWrapperComponent} from './modal-content-wrapper/modal-content-wrapper.component';
import {SubscriptionInventory, SubscriptionKeys} from '../../utils/subscribe.util';
import {ModalOutletComponent} from './modal-outlet/modal-outlet.component';

export const MODAL_DATA = 'APP_MODAL_DATA';

export type ModalPosition = 'center' | 'top';
export interface ModalOptions {
  // set modal data
  data?: any;
  // set modal position
  // default is top
  position?: ModalPosition;
  // set close callback
  onClose?: (returns?: any) => void;
}

@Injectable()
export class ModalService implements OnDestroy {
  // view container reference
  private static viewContainerRef: ViewContainerRef;
  // modal outlet
  private static modalOutlet: ModalOutletComponent;
  // close callback
  private _onClose: (returns?: any) => void;
  // background
  private background: ComponentRef<ModalBackgroundComponent>;
  // content wrapper
  private contentWrapper: ComponentRef<ModalContentWrapperComponent>;
  // inventory
  private inventory: SubscriptionInventory = new SubscriptionInventory<SubscriptionKeys>();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {
  }

  ngOnDestroy(): void {
    this.inventory.unSubscribeAll();
  }

  /**
   * set view container ref
   * @param ref ref
   */
  set viewContainerRef(ref: ViewContainerRef) {
    ModalService.viewContainerRef = ref;
  }

  /**
   * get view container ref
   */
  get viewContainerRef(): ViewContainerRef {
    return ModalService.viewContainerRef;
  }

  /**
   * set modal outlet
   * @param modalOutlet modal outlet
   */
  set modalOutlet(modalOutlet: ModalOutletComponent) {
    ModalService.modalOutlet = modalOutlet;
  }

  /**
   * get modal outlet
   */
  get modalOutlet(): ModalOutletComponent {
    return ModalService.modalOutlet;
  }

  /**
   * open component
   * @param component component
   * @param options modal options
   */
  open<T>(component: Type<T>, options: ModalOptions = {}) {
    const {position, data, onClose} = options;

    this.viewContainerRef.clear();
    this.createBackground();
    this.createContentWrapper(position);
    this.createModal(component, data, onClose);
  }

  /**
   * close activated modal
   */
  close(returns?: any) {
    this.destroyModal();

    if (this._onClose) {
      this._onClose(returns);
    }
  }

  /**
   * create background
   */
  private createBackground() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(ModalBackgroundComponent);
    this.background = this.viewContainerRef.createComponent(factory);

    this.subscribeBackgroundClose(this.background);
  }

  /**
   * create content wrapper
   * @param position modal position
   */
  private createContentWrapper(position: ModalPosition) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(ModalContentWrapperComponent);
    this.contentWrapper = this.viewContainerRef.createComponent(factory);
    this.contentWrapper.changeDetectorRef.detectChanges();

    if (position === 'center') {
      this.modalOutlet.position = 'center';
    } else {
      this.modalOutlet.position = 'top';
    }
  }

  /**
   * create modal component
   * @param component component
   * @param data data
   * @param onClose on close callback
   */
  private createModal<T>(component: Type<T>, data: any, onClose: (returns?: any) => void) {
    const wrapperViewContainerRef = this.contentWrapper.instance.viewContainerRef;
    const factory = this.componentFactoryResolver.resolveComponentFactory(component);
    const injector = Injector.create({
      providers: [
        {
          provide: MODAL_DATA,
          useValue: data,
        },
      ],
    });

    wrapperViewContainerRef.clear();
    wrapperViewContainerRef.createComponent(factory, 0, injector);

    this._onClose = onClose;
  }

  /**
   * subscribe background close
   * @param background background component ref
   */
  private subscribeBackgroundClose(background: ComponentRef<ModalBackgroundComponent>) {
    const sub = background.instance.backgroundClose.subscribe(() => this.close());

    this.inventory.store('backgroundClose', sub);
  }

  /**
   * destroy modal
   */
  private destroyModal() {
    this.background?.destroy();
    this.contentWrapper?.destroy();
  }
}
