<svg
  xmlns="http://www.w3.org/2000/svg" width="16" height="16"
  viewBox="0 0 16 16">
  <g transform="translate(-1107 -27)">
    <g transform="translate(1106.635 26.672)">
      <path
        d="M7.172,2.69l-5.9,9.856A1.394,1.394,0,0,0,2.46,14.637H14.268a1.394,1.394,0,0,0,1.192-2.091L9.556,2.69a1.394,1.394,0,0,0-2.384,0Z"
        fill="#feb948" stroke="#feb948" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      <path d="M8.364,6.273V9.061" fill="none" stroke="#af6d00"
            stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      <path d="M8.364,11.849h.007" fill="none" stroke="#af6d00"
            stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    </g>
  </g>
</svg>
