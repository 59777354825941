import {AfterViewInit, Component, HostBinding, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-modal-content-wrapper',
  templateUrl: './modal-content-wrapper.component.html',
  styleUrls: ['./modal-content-wrapper.component.scss'],
  animations: [
    trigger('appear', [
      state('hidden', style({
        opacity: 0,
      })),
      state('visible', style({
        opacity: 1,
      })),
      transition('hidden => visible', animate('.3s ease-out')),
    ]),
  ],
})
export class ModalContentWrapperComponent implements OnInit, AfterViewInit {
  // viewContainerRef
  @ViewChild('container', {read: ViewContainerRef}) viewContainerRef: ViewContainerRef;
  // set appear state
  @HostBinding('@appear') get appear() {
    return this.visibility;
  }
  // visibility
  visibility = 'hidden';

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.visibility = 'visible';
  }
}
