import { Injectable } from '@angular/core';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})

export class UtilService {


  static accessToken: string = null;

  constructor() {
  }

  /**
   * get user name
   * @param user the user
   */
  static getUserName(user: any) {
    const name = [user.firstName || '', user.familyName || ''].filter(v => v !== '').join(' ');
    return name || user.email;
  }

  /**
   * get name from user
   */
  name(user: any) {
    return UtilService.getUserName(user);
  }

  /**
   * handles the http request errors
   */
  handleHttpError(error) {}

  /**
   * get common error
   * @param err the error response
   */
  getCommonError(err) {
    if (err.name === 'http-err') {
      return err.message;
    }
    if (err && err.error && err.error.message) {
      return err.error.message;
    }
    if (err.status === 403){
      return 'Incorrect Credentials';
    }
    return 'Network error or server not reachable';
  }
}
