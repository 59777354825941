export const SA = 'SA';
export const AM = 'AM';
export const CSA = 'CSA';
export const GA = 'GA';
export const SM = 'SM';
export const SU = 'SU';

export type UserRole = 'SA' | 'AM' | 'CSA' | 'GA' | 'SM' | 'SU';

export interface User {
  id: string;
  email: string;
  permissions: any[];
  status: string;
  firstName: string;
  familyName: string;
  profileUrl: string;
  position: string;
  phone: string;
  notificationPreference: string;
  siteId: string;
  userGroups: string[];
  siteUserType: string;
  siteTagId: string;
  adminUserType: string;
  siteGroupName: string;
  clientId: string;
  createdOn: string;
  updatedOn: string;
  lastLoginOn: string;
  accessToken: string;
  accessTokenExpiredOn: string;

  // frontend helper values
  role: UserRole;
  hideTOS?: boolean;
  emailVerified?: boolean;
}

/**
 * extend user
 */
export const extendUser = (user: User) => {
  user.role = user.permissions[0].role;
  return user;
};
