import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSuccessComponent } from './icon-success.component';



@NgModule({
  declarations: [IconSuccessComponent],
  exports: [
    IconSuccessComponent
  ],
  imports: [
    CommonModule
  ]
})
export class IconSuccessModule { }
