<svg xmlns="http://www.w3.org/2000/svg" width="12.109" height="12.108" viewBox="0 0 12.109 12.108">
  <g transform="translate(-0.491 -0.491)">
    <circle cx="5.455" cy="5.455" r="5.455" transform="translate(1.091 1.091)"
            stroke-width="1.2" stroke="#5f8fb7" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
    <path d="M6.545,8.727V6.545" fill="none" stroke="#5f8fb7" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="1.2"/>
    <path d="M6.545,4.364h.005" fill="none" stroke="#5f8fb7" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="1.2"/>
  </g>
</svg>
