import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {MessageService} from '../../shared-components/message/message.service';
import {isClientUser, isSiteUser} from '../../utils/user.util';
import {User} from '../../models/user';
import {mainStoreSelectors} from '../../stores/main-store/main-store.selectors';

export class RoleGuardBaseGuard {
  constructor(
    protected store: Store,
    protected router: Router,
    protected messageService: MessageService,
  ) {
  }

  /**
   * return user
   */
  get user$() {
    return this.store.select(mainStoreSelectors.user);
  }

  /**
   * redirect by user role
   * @param user user role
   */
  protected redirectByRole(user: User) {
    if (isClientUser(user.role)) {
      this.router.navigate(['/app/client', user.clientId]);
    } else if (isSiteUser(user.role)) {
      this.router.navigate(['/app/site', user.clientId, user.siteId]);
    }

    this.messageService.open('warn', `User doesn't have access to page`);
  }
}
