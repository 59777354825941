import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService } from '../../modal.service';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent implements OnInit {

  @Input() hideCloseIcon: boolean;

  constructor(
    private modalService: ModalService,
  ) {
  }

  ngOnInit(): void {
  }

  /**
   * close modal
   */
  closeModal() {
    this.modalService.close();
  }
}
