import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconInfoComponent } from './icon-info.component';



@NgModule({
  declarations: [IconInfoComponent],
  exports: [
    IconInfoComponent
  ],
  imports: [
    CommonModule
  ]
})
export class IconInfoModule { }
