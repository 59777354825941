import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {mainStoreSelectors} from '../stores/main-store/main-store.selectors';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
  ) {
  }

  /**
   * get user from store
   */
  get user$() {
    return this.store.select(mainStoreSelectors.user);
  }

  get signing$() {
    return this.store.select(mainStoreSelectors.signing);
  }

  /**
   * home page can be activated when user is not in store
   */
  canActivate(): Observable<boolean | UrlTree> {
    return combineLatest([
      this.signing$,
      this.user$,
    ]).pipe(map(res => {
      if (res && !res[0]) {
        const can = !res[1];

        if (!can) {
          this.router.navigate(['/app']);
        }

        return can;
      } else {
        return true;
      }
    }));
  }
}
