import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageOutletComponent } from './message-outlet/message-outlet.component';
import { MessageItemComponent } from './message-item/message-item.component';
import {IconDeleteModule} from '../icons/icon-delete/icon-delete.module';
import {IconWarnModule} from '../icons/icon-warn/icon-warn.module';
import {IconDangerModule} from '../icons/icon-danger/icon-danger.module';
import {IconSuccessModule} from '../icons/icon-success/icon-success.module';
import {IconInfoModule} from '../icons/icon-info/icon-info.module';
import {IconArchiveModule} from '../icons/icon-archive/icon-archive.module';
import {MessageService} from './message.service';



@NgModule({
  declarations: [MessageOutletComponent, MessageItemComponent],
  exports: [
    MessageOutletComponent
  ],
  imports: [
    CommonModule,
    IconDeleteModule,
    IconWarnModule,
    IconDangerModule,
    IconSuccessModule,
    IconInfoModule,
    IconArchiveModule
  ],
  entryComponents: [
    MessageItemComponent,
  ],
  providers: [
    MessageService,
  ],
})
export class MessageModule { }
