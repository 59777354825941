<svg
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="16"
  viewBox="0 0 16 16">
  <path
    d="m 6.807,2.362 -5.9,9.856 a 1.394,1.394 0 0 0 1.188,2.091 h 11.808 a 1.394,1.394 0 0 0 1.192,-2.091 L 9.191,2.362 a 1.394,1.394 0 0 0 -2.384,0 z"
    fill="#ff386d"
    stroke="#ff386d"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"/>
  <path
    d="M 7.999,5.945 V 8.733"
    fill="none"
    stroke="#ffffff"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"/>
  <path
    d="M 7.999,11.521 H 8.006"
    fill="none"
    stroke="#ffffff"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"/>
</svg>
