import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconDangerComponent } from './icon-danger.component';



@NgModule({
  declarations: [IconDangerComponent],
  exports: [
    IconDangerComponent
  ],
  imports: [
    CommonModule
  ]
})
export class IconDangerModule { }
