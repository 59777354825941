import {ComponentFactoryResolver, ComponentRef, Injectable, OnDestroy, ViewContainerRef} from '@angular/core';
import {MessageItemComponent, MessageType} from './message-item/message-item.component';
import {SubscriptionInventory, SubscriptionKeys} from '../../utils/subscribe.util';

@Injectable()
export class MessageService implements OnDestroy {
  // view container reference
  private static viewContainerRef: ViewContainerRef;
  // created message item
  private messageItem: ComponentRef<MessageItemComponent>;
  // inventory
  private inventory: SubscriptionInventory = new SubscriptionInventory<SubscriptionKeys>();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  ngOnDestroy(): void {
    this.inventory.unSubscribeAll();
  }

  /**
   * set view container ref
   * @param ref ref
   */
  set viewContainerRef(ref: ViewContainerRef) {
    MessageService.viewContainerRef = ref;
  }

  /**
   * get view container ref
   */
  get viewContainerRef(): ViewContainerRef {
    return MessageService.viewContainerRef;
  }

  /**
   * open message
   * @param type message type
   * @param message message
   * @param onUndo undo callback
   */
  open(type: MessageType, message: string, onUndo?: () => void) {
    this.destroyMessageItem();
    this.createMessageItem();

    // set inputs and detect changes
    this.messageItem.instance.type = type;
    this.messageItem.instance.message = message;
    this.messageItem.instance.onUndo = onUndo;
    this.messageItem.changeDetectorRef.detectChanges();

    this.subscribeForClose();
  }

  /**
   * create message item
   */
  private createMessageItem() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(MessageItemComponent);

    this.messageItem = this.viewContainerRef.createComponent(factory);
  }

  /**
   * subscribe for close emitter
   */
  private subscribeForClose() {
    const sub = this.messageItem.instance.messageClose.subscribe(() => {
      this.destroyMessageItem();
    });

    this.inventory.store('messageClose', sub);
  }

  /**
   * destroy message item
   */
  private destroyMessageItem() {
    this.messageItem?.destroy();
  }
}
