import {Subscription} from 'rxjs';
import {OnDestroy} from '@angular/core';

export type SubscriptionItem = Subscription | Subscription[];

export interface SubscriptionKeys {
  [key: string]: SubscriptionItem;
}

export class SubscriptionInventory<I = SubscriptionKeys> implements OnDestroy {
  // storage for items
  private storage: SubscriptionKeys = {};

  ngOnDestroy(): void {
    this.unSubscribeAll();
  }

  /**
   * wrapper method for observable
   * @param key identifier key
   * @param item subscription or subscriptions
   */
  store<T>(key: keyof I, item: SubscriptionItem): void {
    // unsubscribe before store
    this.unSubscribe(key);
    this.storage[key as string] = item;
  }

  /**
   * unsubscribe item by key
   * @param key identifier key
   */
  unSubscribe(key: keyof I): void {
    const item = this.storage[key as string];

    if (item) {
      (item instanceof Array ? item : [item]).forEach(sub => sub.unsubscribe());
    }
  }

  /**
   * unsubscribe all items
   */
  unSubscribeAll(): void {
    Object.keys(this.storage).forEach(key => this.unSubscribe(key as keyof I));
  }
}
