<svg
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="16"
  viewBox="0 0 16 16">
  <path
    d="M 1.455,3.637 H 14.546"
    id="path4335"
    fill="none"
    stroke="#5f8fb7"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"/>
  <path
    d="m 13.091,3.637 v 10.181 a 1.455,1.455 0 0 1 -1.454,1.455 H 4.364 A 1.455,1.455 0 0 1 2.909,13.818 V 3.637 m 2.182,0 V 2.182 A 1.455,1.455 0 0 1 6.546,0.728 h 2.909 a 1.455,1.455 0 0 1 1.455,1.455 v 1.454"
    id="path4337"
    fill="none"
    stroke="#5f8fb7"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"/>
  <path
    d="m 6.546,7.273 v 4.364"
    id="path4339"
    fill="none"
    stroke="#5f8fb7"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"/>
  <path
    d="m 9.455,7.273 v 4.364"
    id="path4341"
    fill="none"
    stroke="#5f8fb7"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"/>
</svg>
