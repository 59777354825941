<svg
  xmlns="http://www.w3.org/2000/svg" width="16" height="16"
  viewBox="0 0 16 16">
  <g transform="translate(0.809 0.889)">
    <path d="M15.7,7.909v.657A7.138,7.138,0,1,1,11.47,2.041"
          transform="translate(-1.427 -1.423)" fill="none" stroke="#8dc63f" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="1.5"/>
    <path d="M15.7,2.855,8.565,10,6.424,7.859" transform="translate(-1.427 -1.423)"
          fill="none" stroke="#8dc63f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
  </g>
</svg>
