import {createReducer, on} from '@ngrx/store';
import {User} from '../../models/user';
import {mainStoreActions} from './main-store.actions';
import {SideAlertsType} from '../../components/application/common/side-alerts/side-alerts.component';

export interface MainStoreState {
  user: User;
  sideAlertType: SideAlertsType;
  sideAlertId: string;
  search: string;
  comeFromSearchResult: boolean;
  userProfile: User;
  signing: boolean;
}

export const MainStoreInitialState: MainStoreState = {
  user: null,
  sideAlertType: 'recent',
  sideAlertId: null,
  search: null,
  comeFromSearchResult: false,
  userProfile: null,
  signing: false,
};

const reducer = createReducer(
  MainStoreInitialState,
  // set user
  on(
    mainStoreActions.setUser,
    (state, {user}) => ({
      ...state,
      user,
    }),
  ),
  on(
    mainStoreActions.setSideAlertType,
    (state, {sideAlertType}) => ({
      ...state,
      sideAlertType,
    }),
  ),
  on(
    mainStoreActions.setSideAlertId,
    (state, {sideAlertId}) => ({
      ...state,
      sideAlertId,
    }),
  ),
  on(
    mainStoreActions.setSearchText,
    (state, {search}) => ({
      ...state,
      search,
    }),
  ),
  on(
    mainStoreActions.setComeFromSearchResult,
    (state, {comeFromSearchResult}) => ({
      ...state,
      comeFromSearchResult,
    }),
  ),
  on(
    mainStoreActions.setUserProfile,
    (state, {user}) => ({
      ...state,
      userProfile: user,
    }),
  ),
  on(
    mainStoreActions.setSigning,
    (state, {signing}) => ({
      ...state,
      signing,
    }),
  ),
  on(
    mainStoreActions.clear,
    () => ({
      ...MainStoreInitialState,
    }),
  ),
);

/**
 * main store reducer
 * @param state state
 * @param action action
 */
export function mainStoreReducer(state, action) {
  return reducer(state, action);
}
