import {Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

export type MessageType = 'success' | 'archive' | 'delete' | 'info' | 'warn' | 'danger';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss'],
  animations: [
    trigger('toast', [
      state('toast', style({
        opacity: 1,
        marginBottom: 20,
      })),
      state('void', style({
        opacity: 0,
        marginBottom: -20,
      })),
      transition('void <=> toast', animate('.15s ease-out')),
    ]),
  ]
})
export class MessageItemComponent implements OnInit, OnDestroy {
  // message type
  @Input() type: MessageType;
  // message
  @Input() message: string;
  // set undo callback
  @Input() set onUndo(onUndo: () => void) {
    if (onUndo) {
      this._onUndo = onUndo;
    }
  }
  // message close emitter
  @Output() messageClose: EventEmitter<void> = new EventEmitter<void>();
  // bind animation
  @HostBinding('@toast') toast = 'toast';
  // count
  count = 5;
  // undo callback
  private _onUndo: () => void;
  // timer
  private timer;

  constructor() { }

  ngOnInit(): void {
    this.startCountdown();
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer);
  }

  /**
   * return true when undo callback is existing
   */
  get hasUndo() {
    return !!this._onUndo;
  }

  /**
   * countdown only started when undo callback is set
   */
  private startCountdown() {
    this.timer = setInterval(() => {
      this.count--;

      if (this.count === 0) {
        this.messageClose.emit();
      }
    }, 1000);
  }

  /**
   * undo action
   */
  undoAction() {
    if (this.hasUndo) {
      this._onUndo();
    }

    this.messageClose.emit();
  }
}
